<template>
  <div class="app">
    <van-nav-bar :title="$t('newAdd.RechargeRecordTitle')">
      <van-icon slot="left" name="arrow-left" color="#fff" @click="$router.back()" />
    </van-nav-bar>
    <div class="tab-lists">
      <div class="tab-item" :class="[status === item.params.status ? 'on-tab-item' : '']"
        v-for="(item, index) in tabLists" :key="index" @click="handleTabItme(item.params.status)">
        {{ item.title }}
      </div>
    </div>
    <div class="wrap">
      <van-list v-model="loading" :finished="finished" :finished-text="$t('Hint.notMore') + '...'"
        :loading-text="$t('Hint.loading')" @load="onLoad">
        <!-- <scroll-view class="list" scroll-y @scrolltolower="handleLoadMore"> -->
        <div class="lists">
          <div class="list-item" v-for="(item, index) in lists" :key="index" @click="copy(item)">
            <!-- <div class="list-item-title">
            <span class="list-item-title-text">{{ item.type }}</span>
            <span v-if="item.status === '已完成'" class="list-item-title-sub">{{ `（${$t("newAdd.succeed")}）` }}</span>
            <span v-else class="list-item-title-sub-green">（{{
            item.status === "待审核"
            ? $t("newAdd.toAudit")
            : $t("newAdd.rests")
            }}）</span>
          </div>
          <div class="list-item-order-wrap">
            <span class="list-item-order">{{ $t("newAdd.OrderNum") }}&nbsp;{{ item.payid }}</span>
            <i class="iconfont icon">&#xe621;</i>
          </div>
          <div class="list-item-bottom">
            <div class="time">
              <div class="list-item-indate">{{ $format(item.indate) }}</div>
              <i class="iconfont icon">&#xe621;</i>
            </div>
            <div class="amount">
              <span class="list-item-money"> {{ item.money }}</span>
              <i class="iconfont icon">&#xe621;</i>
            </div>
          </div> -->
            <div class="list-item-left">
              <div class="list-item-title">
                <span class="list-item-title-text">{{ item.type }}</span>
                <span v-if="item.status === '已完成'" class="list-item-title-sub">{{ `（${$t("newAdd.succeed")}）` }}</span>
                <span v-else class="list-item-title-sub-green">（{{
      item.status === "待审核"
        ? $t("newAdd.toAudit")
        : $t("newAdd.rests")
    }}）</span>
              </div>
              <span class="list-item-indate">{{ $format(item.indate) }}</span>
            </div>
            <div class="list-item-right">
              <span class="list-item-order">{{ $t("newAdd.OrderNum") }}&nbsp;{{ item.payid }}</span>
              <div class="list-item-mbox">
                <span class="list-item-money"> {{ item.money }}</span>
                <span class="list-item-addMoney" v-if="item.status === '已完成' && item.money !== item.addMoney">({{
      $t("newAdd.Arrive") }} {{ item.addMoney }})</span>
              </div>
            </div>
            <!-- <i class="iconfont icon">&#xe621;</i> -->
          </div>
        </div>
        <!-- language-ch 没有任何记录 -->
        <!-- <div v-if="alreadyLastPage" class="nolist">
        There's no record of anything
      </div> -->
        <!-- </scroll-view> -->
      </van-list>
    </div>
  </div>
</template>

<script>
import { NavBar, cell, icon, field, Dialog, Toast, List } from "vant";
import { GET_RECHARGE_LISTS } from "@/api";
import { formatDate } from "@/utils/tools";
import verConfig from '../../utils/verConfig';
export default {
  components: {
    [NavBar.name]: NavBar,
    [cell.name]: cell,
    [icon.name]: icon,
    [field.name]: field,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
    [List.name]: List,
  },
  data() {
    return {
      loading: false,
      finished: false,
      status: 0,
      alreadyLastPage: false,
      pages: 1,
      lists: [],
      tabLists: [
        {
          title: this.$t("newAdd.all"),
          params: { status: 0 },
        },
        {
          title: this.$t("newAdd.Completed"),
          params: { status: 1 },
        },
        {
          title: this.$t("newAdd.unfinished"),
          params: { status: 2 },
        },
      ],
      stateObj: {
        已汇款: "success",
        未通过: "not pass",
        汇款中: "processing",
        idpay: "Pay on behalf of",
      },
    };
  },

  mounted() { },

  methods: {
    onLoad() {
      this.getLists();
    },
    handleTabItme(status) {
      this.status = status;
      this.alreadyLastPage = false;
      this.pages = 1;
      this.lists = [];
      this.loading = false;
      this.finished = false;
      this.getLists();
    },
    handleLoadMore() {
      if (!this.alreadyLastPage) {
        this.getLists();
      }
    },
    getLists() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_RECHARGE_LISTS({
        status: this.status,
        page: this.pages,
      })
        .then((r) => {
          Toast.clear();
          this.loading = false;
          if (r.data.ret === 1) {
            Toast.clear();
            let dataLists = r.data.data.lists;
            if (r.data.data.currentPage > 1) {
              this.pages++;
              for (let i = 0; i < dataLists.length; i++) {
                this.lists.push(dataLists[i]);
              }
              if (r.data.data.currentPage === this.pages) {
                this.finished = true;
                this.loading = false;
              } else {
                this.finished = false;
                this.loading = true;
              }
            } else {
              Toast.clear();
              this.lists = dataLists;
              this.finished = true;
              this.loading = false;
            }

            this.alreadyLastPage = this.pages === 1 && dataLists.length === 0;
          } else {
            Toast.clear();
            this.$toast(r.data.msg);
            this.loading = false;
            this.finished = true;
          }
        })
        .catch((e) => {
          Toast.clear();
          this.loading = false;
          console.error(e);
        });
    },
    dateEvent(indate) {
      return formatDate("Y-m-d H:i:s", indate);
    },
    copy(item) {
      const copyText = `time: ${this.$format(item.indate)}\norder number: ${item.payid}\namount: ${item.money}`
      this.$copyText(copyText).then((e) => {
        Toast("Copy successfully");
      });
    }
  },
};
</script>

<style scoped lang="less">
@import url('../../assets/iconfonts/iconfont.css');
@background: var(--nav-background);
@color: var(--theme);

.app {
  flex: 1;
  width: 100%;
  height: 100vh;
  // background-color: #f4f8f9;
  background-color: var(--nav-background);

  /deep/ .van-nav-bar {
    background: @background;
  }

  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      background: @background;
    }

    /deep/.van-nav-bar__title {
      color: #fff;
    }
  }

  >.tab-lists {
    display: flex;
    align-items: center;
    background-color: var(--bg);

    // border-bottom: calc(1rem / 16) solid #f4f8f9;
    >.tab-item {
      width: 33.33%;
      height: calc(40rem / 16);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: calc(12rem / 16);
      // color: #222;
      color: var(--sizeColor)
    }

    >.on-tab-item {
      // color: @color;
      color: var(--theme)
    }
  }
}

.wrap {
  min-height: calc(100vh - (46rem / 16) - (40rem / 16));
}

.list {
  flex: 1;
  width: 100%;
  height: calc(100vh - (41rem / 16) - (44rem / 16));
  background-color: #ffffff;
}

.nolist {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  padding-top: calc(25rem / 16);
  padding-bottom: calc(25rem / 16);
  text-align: center;
  padding-bottom: calc(12rem / 16);
  color: #999999;
  margin: auto;
}

.lists {
  padding: calc(12rem / 16);
  display: flex;
  flex-direction: column;
  row-gap: calc(12rem / 16);
}

.list-item {
  padding: calc(12rem / 16);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg);
  box-sizing: border-box;
  position: relative;
  border-radius: calc(12rem / 16);
}

.icon {
  color: var(--theme);
  position: absolute;
  right: 20px;
  bottom: 6px;
}

.list-item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.list-item-order-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 6px;
}

.list-item-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}

.list-item-title-text {
  font-size: calc(23rem / 16);
  // color: #242424;
  color: var(--sizeColor);
}

.list-item-title-sub,
.list-item-title-sub-green {
  font-size: calc(12rem / 16);
  color: #1bb019;
}

.list-item-title-sub-green {
  color: @color;
}

.list-item-reason {
  margin-top: calc(6rem / 16);
  font-size: calc(12rem / 16);
  color: #ff6e1c;
  width: calc(520rem / 16);
}

.list-item-bank {
  margin-top: calc(6rem / 16);
  font-size: calc(12rem / 16);
  color: #888888;
}

.time {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 50%;
}

.amount {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 30%;
}


.list-item-indate {
  margin-top: calc(6rem / 16);
  font-size: calc(12rem / 16);
  // color: #888888;
  color: var(--sizeColor);
  opacity: .6;
}

.list-item-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.list-item-order {
  font-size: calc(10rem / 16);
  color: var(--sizeColor);
}

.list-item-money {
  margin-top: calc(6rem / 16);
  font-size: calc(17rem / 16);
  color: @color;
}
</style>